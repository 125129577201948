<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-card-title>
        <span class="text-body-1 primary--text">Editar Cargo</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <validation-alert :validationErrors="validationErrors" />
          <v-form ref="form" v-model="isValidFormDialog" lazy-validation>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-select
                  v-model="editedItem.company_id"
                  :items="companies"
                  :rules="companyRules"
                  item-text="name"
                  item-value="id"
                  label="Empresa"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="editedItem.name"
                  :rules="nameRules"
                  label="Nombre"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="editedItem.code"
                  :rules="codeRules"
                  label="Código"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="close()"> Cancelar </v-btn>
        <v-btn
          color="blue darken-1"
          :loading="isLoading"
          :disabled="isLoading"
          @click="save()"
          text
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ValidationAlert from "@/components/global/ValidationAlert.vue";
import { $EventBus } from "@/main";
export default {
  name: "EditChargeDialog",

  props: {
    charge: Object,
  },

  data: () => ({
    isValidFormDialog: false,
    companyRules: [(v) => !!v || "Campo requerido"],
    nameRules: [(v) => !!v || "Campo requerido"],
    codeRules: [(v) => !!v || "Campo requerido"],
    dialog: false,
    editedIndex: -1,
    editedItem: {
      name: "",
      code: "",
      company_id: null
    },
    defaultItem: {
      name: "",
      code: "",
      company_id: null
    },
    isLoading: false,
  }),

  computed: {
    companies() {
      return this.$store.getters["companies/getCompaniesList"];
    },
    validationErrors() {
      return this.$store.getters["charges/getValidationErrors"];
    },
  },

  watch: {
    charge(val) {
      if (val && val.id) {
        this.dialog = true;
        this.editedItem = val;
        this.$store.dispatch("companies/getCompaniesList");
      }
    },
    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.$store.dispatch("charges/cleanValidationErrors");
        this.$refs.form.resetValidation();
      });
    },

    showSnack(color = "", text = "") {
      $EventBus.$emit("showSnack", { color, text });
    },

    async save() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        try {
          let dataUpdate = Object.assign({}, this.editedItem);
          delete dataUpdate.company;

          await this.$store.dispatch("charges/updateCharge", dataUpdate);
          this.showSnack("green", "Cargo actualizado");
          this.$emit("resetFilter");
          this.close();
        } catch (error) {}
        this.isLoading = false;
      }
    },
  },
   components: {
    ValidationAlert,
  },
};
</script>
