import CreateChargeDialog from "@/components/charges/charge/CreateChargeDialog.vue";
import EditChargeDialog from "@/components/charges/charge/EditChargeDialog.vue";
import DeleteChargeDialog from "@/components/charges/charge/DeleteChargeDialog.vue";
import SearchInput from "@/components/global/SearchInput.vue";

export default {
  name: "Charges",

  data: () => ({
    search: "",
    headers: [
        {
            text: "Nombre",
            value: "name",
            sortable: false,
            class: "primary--text",
        },
        {
            text: "Código",
            value: "code",
            sortable: false,
            class: "primary--text",
        },
        {
            text: "Empresa",
            value: "company",
            align: "center",
            sortable: false,
            class: "primary--text",
        },
        {
            text: "Acciones",
            value: "actions",
            sortable: false,
            class: "primary--text",
        },
    ],
    currentPage: 1,
    editedItem: {},
    deletedItem: {},
  }),

  computed: {
    charges() {
      return this.$store.getters["charges/getCharges"];
    },
    paginationPages() {
      return this.$store.getters["charges/getPaginationPages"];
    },
    isLoading() {
      return this.$store.state.charges.isLoading;
    },
  },

  watch: {},

  created() {
    this.$store.dispatch("charges/getCharges", { page: 1 });
  },

  methods: {
    editItem(item) {
      this.editedItem = { ...item };
    },

    deleteItem(item) {
      this.deletedItem = { ...item };
    },

    listenPagination() {
      this.$store.dispatch("charges/getCharges", {
        page: this.currentPage,
        search: this.search,
      });
    },

    resetFilter() {
      this.search = "";
      this.currentPage = 1;
    },
  },
  components: {
    CreateChargeDialog,
    EditChargeDialog,
    DeleteChargeDialog,
    SearchInput,
  },
};
